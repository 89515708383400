// import { TotalViewLayout } from "@streamllc/shared/ocb"
import { FormEvent } from "react"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { coerce, z } from "zod"

import { OCBAppViews } from "@/entities/app/ocb/ocb.types"
import ColorPicker from "@/shared/components/extrinsic/ColorPicker/ColorPicker"
import Field from "@/shared/components/intrinsic/Field/Field"
import FileSelector from "@/shared/components/intrinsic/FileSelector/FileSelector"
import FileSelectorMultiple from "@/shared/components/intrinsic/FileSelector/FileSelectorMultiple"

// import Selector from "@/shared/components/intrinsic/Selector/Selector"
// import { optionsFromEnum } from "@/shared/components/intrinsic/Selector/Selector.helpers"
import FontFamilySelector from "../components/FontFamilySelector"
import FontSizeSelector from "../components/FontSizeSelector"
import SettingsGroup from "../components/SettingsGroup/SettingsGroup"
import SettingsRow from "../components/SettingsRow/SettingsRow"
import useTabSettings from "../hooks/useTabSettings"

const FONTS = ["Arial", "Futura", "Georgia", "Helvetica", "Verdana"] as const

const form = new ZodForm({
  fontFamily: z.enum(FONTS),
  fontSize: coerce.number(),
  fontColor: coerce.string(),
  thanksMessage: coerce.string(),
  backgrounds: z.array(z.instanceof(File)).default([]).transform(files => files.slice(0, 5)),
  video: z.instanceof(File).optional().refine(file => (file?.size ?? 0) <= 20 * 1024 * 1024, "Video must not exceed 20MB"),
  logo: z.instanceof(File).optional(),
  background: coerce.string(),
  // layout: z.nativeEnum(TotalViewLayout).default(TotalViewLayout.Layout1),

  orderTimeout: coerce.number().transform(value => value > 150 ? 150 : value),
  totalScreenEnabled: coerce.boolean(),
  totalScreenTimeout: coerce.number().transform(value => value > 50 ? 50 : value),
})


form.object._type satisfies OCBAppViews["totalScreen"]


function TotalTabSettings() {
  const [totalScreen, setTotalScreen] = useTabSettings("totalScreen")
  const { fieldIssues } = useZodFormIssues(form)

  function onChange(event: FormEvent<HTMLFormElement>) {
    const field = form.parseCurrentField(event)

    if (field == null) {
      if (event.target instanceof HTMLInputElement === false) return

      setTotalScreen({ [event.target.name]: null })
      return
    }

    setTotalScreen({ [field.name]: field.value })
  }

  return (
    <form onChange={onChange}>
      <SettingsGroup title="TEXT">
        {/* <SettingsRow title="Layout">
          <Selector placeholder="Layout" name={form.fields.layout} value={totalScreen.layout}>
            {optionsFromEnum(TotalViewLayout)}
          </Selector>
        </SettingsRow> */}
        <FontFamilySelector width="100%" value={totalScreen.fontFamily} name={form.fields.fontFamily} />
        <SettingsRow>
          <FontSizeSelector start={14} end={132} step={2} value={totalScreen.fontSize} name={form.fields.fontSize} />
          <ColorPicker label="Color" value={totalScreen.fontColor} name={form.fields.fontColor} />
        </SettingsRow>
        <Field placeholder="Message" value={totalScreen.thanksMessage} name={form.fields.thanksMessage}>Message</Field>

      </SettingsGroup>
      <SettingsGroup title="BACKGROUND">
        <ColorPicker label="Color" value={totalScreen.background} name={form.fields.background} />
        <FileSelectorMultiple
          label="Images"
          accept="image/*"
          clearable
          name={form.fields.backgrounds}
          value={totalScreen.backgrounds}
          filesAmmount={1}
        />
        <FileSelector
          name={form.fields.video}
          value={totalScreen.video}
          defaultValue={totalScreen.video}
          customValidity={fieldIssues.video}
          label="Video"
          accept="video/*"
          clearable
        />
      </SettingsGroup>
    </form>
  )
}

export default TotalTabSettings
