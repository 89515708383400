import "./EditableAvatar.scss"

import { ChangeEvent, useState } from "react"

import ColoredLetter from "@/shared/components/extrinsic/ColoredLetter/ColoredLetter"
import { classWithModifiers } from "@/utils/common"
import useAsyncBoundary from "@/utils/hooks/useAsyncBoundary"
import FileUtils, { ImageResolution } from "@/utils/transform/file"

import Icon from "../../intrinsic/Icon/Icon"
import Loader from "../Loader/Loader"

interface EditableAvatarProps {
  name?: string
  defaultImage?: string
  firstName?: string
  onChange?(file: File): void | Promise<unknown>
  pending?: boolean

  /**
   * Will display image preview containing it instead of covering.
   */
  contain?: boolean

  /** In bytes. */
  maxSize?: number
  /** In pixels. */
  maxResolution?: [number, number]
}

function EditableAvatar(props: EditableAvatarProps) {
  const [image, setImage] = useState<string | null>(props.defaultImage ?? null)
  const [{ pending }, asyncBoundary] = useAsyncBoundary()

  const [invalid, setInvalid] = useState(false)

  async function onChange(event: ChangeEvent<HTMLInputElement>) {
    const target = event.currentTarget

    // checks
    const files = target.files
    if (files === null) return

    const file = files[0] as File | undefined
    if (file == null) return

    setInvalid(false)

    if (exceedsMaxSize(file.size) || exceedsMaxResolution(await FileUtils.getImageResolution(file))) {
      setInvalid(true)
      // Empties the files.
      target.files = new DataTransfer().files
      return
    }

    // awaits
    if (props.onChange) {
      await asyncBoundary(() => props.onChange?.(file))
    }

    // updates
    if (!/^image\/(png|jpg|jpeg)$/.test(file.type)) return
    setImage(URL.createObjectURL(file))
  }

  function exceedsMaxSize(size: number): boolean {
    if (props.maxSize == null) return false

    return size > props.maxSize
  }
  function exceedsMaxResolution(resolution: ImageResolution): boolean {
    if (resolution.width > maxWidth) return true
    if (resolution.height > maxHeight) return true

    return false
  }

  function getErrorMessage() {
    if (props.maxSize != null && props.maxResolution == null) {
      return `Its size should not exceed ${(props.maxSize ?? 0) / 1024 / 1024}MB`
    }

    if (props.maxSize == null && props.maxResolution != null) {
      return `Its resolution should not exceed ${maxWidth}x${maxHeight}px`
    }

    return `Its size should not exceed ${maxWidth}x${maxHeight}px and ${(props.maxSize ?? 0) / 1024 / 1024}MB`
  }

  const [maxWidth, maxHeight] = props.maxResolution || [Infinity, Infinity]
  return (
    <div className="editable-avatar">
      <div className={classWithModifiers("editable-avatar__container", (pending || props.pending) && "pending")}>
        {image && (
          <img src={image} alt="avatar" className={classWithModifiers("editable-avatar__image", props.contain && "contain")} />
        )}
        {!image && props.firstName && (
          <div className={classWithModifiers("editable-avatar__image", props.contain && "contain")} >
            <ColoredLetter letter={props.firstName} />
          </div>
        )}
        <label className="editable-avatar__cover">
          <Icon className="editable-avatar__icon" name="touch" />
          <input className="editable-avatar__input" name={props.name} type="file" accept=".png, .jpg, .jpeg" onChange={onChange} aria-hidden={false} />
        </label>
        <div className="editable-avatar__loader">
          <Loader />
        </div>
      </div>
      {invalid && (
        <div className="editable-avatar__validity">
          Image cannot be uploaded. {getErrorMessage()}
        </div>
      )}
    </div>
  )
}

export default EditableAvatar
