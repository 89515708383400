import "./ColoredLetter.scss"


interface ColoredLetterProps {
  letter: string
  baseHSL?: [number, number, number]
}

function ColoredLetter(props: ColoredLetterProps) {
  const [h, s, l] = props.baseHSL ?? [255, 50, 50]

  const alphabet = "abcdefghijklmnopqrstuvwxyz".split("")

  const letterIndex = alphabet.indexOf(props.letter.toLowerCase())
  const letterBackground = `hsl(${h / alphabet.length * letterIndex} ${s}% ${l}%)`

  return (
    <span className="colored-letter" style={{ backgroundColor: letterBackground, fontSize: "24px" }}>{props.letter}</span>
  )
}

export default ColoredLetter
