import "./SettingsTabsTotal.scss"

import { TotalScreen } from "@streamllc/shared/ocb"
import { TotalViewLayout } from "@streamllc/shared/ocb"

import useTabSettings from "../../../../hooks/useTabSettings"

function SettingsTabsTotal() {
  const containerWidth = 280
  const scaleRatio = containerWidth / window.innerWidth
  const total = 32

  const [totalSettings] = useTabSettings("totalScreen")

  return (
    <div className="settings-tabs-total">
      <TotalScreen
        customization={{
          ...totalSettings,
          layout: TotalViewLayout.Layout1,
        }}
        scaleRatio={scaleRatio}
        total={total}
      />

    </div>
  )
}

export default SettingsTabsTotal
