import { useMemo, useState } from "react"
import { useEffect } from "react"

import { Themeable } from "@/app/types"
import Fields from "@/shared/layouts/Fields/Fields"
import { inputValue, toggleState } from "@/utils/common"

import PasswordFieldView from "./PasswordFieldView"

interface NewPasswordFieldsProps extends Themeable {
  password: string
  confirmPassword: string
  customValidity?: string
  onPasswordChange(password: string): void
  onConfirmPasswordChange(confirmPassword: string): void
  onConfirm?(matched: boolean): void
}

function NewPasswordFields(props: NewPasswordFieldsProps) {
  const [firstPasswordHidden, setFirstPasswordHidden] = useState(true)
  const [secondPasswordHidden, setSecondPasswordHidden] = useState(true)

  const passwordsMatched = useMemo(
    () => props.password === props.confirmPassword,
    [props.password, props.confirmPassword]
  )

  // Notify parent if passwords match
  useEffect(() => props.onConfirm?.(passwordsMatched), [passwordsMatched])

  return (
    <Fields>
      <PasswordFieldView
        theme={props.theme}
        autoComplete="new-password"
        placeholder="Enter new password"
        customValidity={props.customValidity} // Display validation error
        hidden={firstPasswordHidden}
        onHiddenToggle={toggleState(setFirstPasswordHidden)}
        onChange={inputValue(props.onPasswordChange)}
      />

      <PasswordFieldView
        theme={props.theme}
        autoComplete="new-password"
        placeholder="Confirm your password"
        customValidity={
          !passwordsMatched && props.confirmPassword
            ? "Passwords do not match"
            : ""
        }
        hidden={secondPasswordHidden}
        onHiddenToggle={toggleState(setSecondPasswordHidden)}
        onChange={inputValue(props.onConfirmPasswordChange)}
      />
    </Fields>
  )
}

export default NewPasswordFields
