import "./SettingsTabs.scss"

import { OCBView } from "@streamllc/shared/ocb"
import { useEffect, useRef, useState } from "react"

import TabLink from "@/services/TabRouter/TabLink"
import TabRoute from "@/services/TabRouter/TabRoute"
import { useRouterTab } from "@/services/TabRouter/tabRouterContext"
import Button from "@/shared/components/intrinsic/Button/Button"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import useBroadcastChannel from "@/utils/hooks/useBroadcastChannel"
import useBroadcastMessage from "@/utils/hooks/useBroadcastMessage"
import WindowTab from "@/utils/transform/WindowTab"

import SettingsTabsOrder from "./components/SettingsTabsOrder/SettingsTabsOrder"
import SettingsTabsTotal from "./components/SettingsTabsTotal/SettingsTabsTotal"
import SettingsTabsWelcome from "./components/SettingsTabsWelcome/SettingsTabsWelcome"

import useSettings from "../../hooks/useSettings"


interface SettingsTabsProps {
  name: string
}

function SettingsTabs(props: SettingsTabsProps) {
  const tab = useRouterTab()
  const { settings } = useSettings()

  const previewChannel = useBroadcastChannel(`OCB/${props.name}/PREVIEW`)
  const previewChannelClientId = useBroadcastMessage<string>(previewChannel, "CONNECT")

  useEffect(emitPreviewUpdate, [emitPreviewUpdate, previewChannelClientId])
  function emitPreviewUpdate() {
    previewChannel.postMessage({ type: "UPDATE", payload: { view: tab, views: settings } })
  }


  const popupRef = useRef<WindowTab>()
  useEffect(() => popupRef.current?.replaceUrl("/ocb-preview/" + props.name), [props.name])

  const [expanded, setExpanded] = useState(false)

  function onExpand() {
    const popup = new WindowTab("/ocb-preview/" + props.name, "", "popup")
    if (popup.window == null) return

    setExpanded(true)

    popupRef.current = popup
    popup.on("close", () => setExpanded(false))

    popup.window.addEventListener("DOMContentLoaded", () => {
      popup.window?.queueMicrotask(emitPreviewUpdate)
    }, { once: true })
  }

  function onCollapse() {
    popupRef.current?.window?.close()
    popupRef.current = undefined

    setExpanded(false)
  }

  //#endregion Expanded Popup

  return (
    <div className="settings-tabs">
      <div className="settings-tabs__tabs">
        <TabLink className="settings-tabs__tab" to={OCBView.Welcome}>Welcome</TabLink>
        <TabLink className="settings-tabs__tab" to={OCBView.Order}>Order</TabLink>
        <TabLink className="settings-tabs__tab" to={OCBView.Total}>Total</TabLink>
      </div>
      {expanded && (
        <div className="settings-tabs__preview-expanded">
          <p>Active preview session</p>
          <Button size="smaller" color="transparent" onClick={onCollapse}><Icon name="cross" /></Button>
        </div>
      )}
      {!expanded && (
        <div className="settings-tabs__preview">
          <TabRoute path={OCBView.Welcome}>
            <SettingsTabsWelcome />
          </TabRoute>
          <TabRoute path={OCBView.Order}>
            <SettingsTabsOrder />
          </TabRoute>
          <TabRoute path={OCBView.Total}>
            <SettingsTabsTotal />
          </TabRoute>
          <button className="settings-tabs__expand" type="button" onClick={onExpand}>
            <Icon name="expand" />
          </button>
        </div>
      )}
    </div>
  )
}

export default SettingsTabs
