import { FormEvent, useState } from "react"
import { Link } from "react-router-dom"

import Button from "@/shared/components/intrinsic/Button/Button"
import Icon from "@/shared/components/intrinsic/Icon/Icon"

import { passwordPattern } from "../../helpers"
import NewPasswordFields from "../PasswordField/NewPasswordFields"

interface NewPasswordFormProps {
  onSubmit?(password: string): boolean | Promise<boolean>
}

function NewPasswordForm(props: NewPasswordFormProps) {
  const [password, setPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [passwordError, setPasswordError] = useState<string>("")
  const [passwordConfirmed, setPasswordConfirmed] = useState(false)
  const [operationSuccessful, setOperationSuccessful] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    setPasswordError("")

    if (!passwordPattern.test(password)) {
      setPasswordError(
        "Password must contain at least 8 characters, including: upper case, lower case, number and a special character"
      )
      return
    }

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match")
      return
    }

    setIsSubmitting(true)

    try {
      const success = await props.onSubmit?.(password)
      setOperationSuccessful(success ?? false)
    } catch (error) {
      console.error("Failed to set password:", error)
      setOperationSuccessful(false)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <NewPasswordFields
        password={password}
        confirmPassword={confirmPassword}
        onPasswordChange={setPassword}
        onConfirmPasswordChange={setConfirmPassword}
        customValidity={passwordError}
        onConfirm={setPasswordConfirmed}
      />
      <Button
        size="big"
        type="submit"
        color={operationSuccessful ? "green" : undefined}
        disabled={!passwordConfirmed || operationSuccessful || isSubmitting}
      >
        {operationSuccessful ? (
          <>
            Password set successful <Icon name="check" />
          </>
        ) : isSubmitting ? (
          "Setting password..."
        ) : (
          "Set password"
        )}
      </Button>

      {operationSuccessful && (
        <Link
          to="/user/login"
          style={{
            position: "absolute",
            top: "200px",
            right: "101px",
            color: "#404043",
            textDecoration: "underline"
          }}
        >
          Redirecting to the login page in 5 sec...
        </Link>
      )}
    </form>
  )
}

export default NewPasswordForm
