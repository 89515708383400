import "./PopupPersonalSettings.scss"

import { FormEvent, useState } from "react"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { coerce } from "zod"

import { Link } from "@/app/AppRouter"
import { UserUpdateDTO } from "@/entities/user"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import EditableAvatar from "@/shared/components/extrinsic/EditableAvatar/EditableAvatar"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import Fields from "@/shared/layouts/Fields/Fields"
import Form from "@/shared/layouts/Form/Form"
import { useAppSelector } from "@/store/hooks"
import useFormStatus from "@/utils/hooks/useFormStatus"
import Bytes from "@/utils/transform/Bytes"


const form = new ZodForm({
  firstName: coerce.string().min(3, "Add at least 3 character"),
  lastName: coerce.string().min(3, "Add at least 3 character"),
  email: coerce.string().email()
})
form.object._type satisfies UserUpdateDTO

interface PopupPersonalSettingsProps {
  onSubmit?(dto: UserUpdateDTO): Promise<void> | void
  onAvatarChange?(avatar: File): Promise<void> | void
}

function PopupPersonalSettings(props: PopupPersonalSettingsProps) {
  const user = useAppSelector(state => state.user)
  const [formUpdated, setFormUpdated] = useState(false)

  const formStatus = useFormStatus(form, onSubmit)
  const { fieldIssues } = useZodFormIssues(form)

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (fields == null) return

    await props.onSubmit?.(fields)
    setFormUpdated(false)
  }

  function onChange(event: FormEvent<HTMLFormElement>){
    const field = form.parseCurrentField(event)
    if (!field) return
    const firstNameUpdated = field.name === "firstName" && field.value !== user.firstName
    const lastNameUpdated = field.name === "lastName" && field.value !== user.lastName
    
    if (firstNameUpdated || lastNameUpdated){
      setFormUpdated(true)
    }else{
      setFormUpdated(false)
    }
  }

  console.log("Avatar", user)

  return (
    <PopupLayout width="45em">
      <Icon className="popup-personal-settings__gear" name="gear" />
      <h5 className="popup-personal-settings__title">Personal settings</h5>
      <Form onChange={onChange} onSubmit={formStatus.submit}>
        <div className="popup-personal-settings__extra">
          <EditableAvatar defaultImage={user.avatar} maxSize={Bytes.MB(10)} onChange={props.onAvatarChange} firstName={user.firstName[0]} />
          <Link className="popup-personal-settings__password" to="/user/profile/change-password">Change password</Link>
        </div>
        <Fields>
          <div className="popup-personal-settings__row">
            <Field name={form.fields.firstName} placeholder="Name" defaultValue={user.firstName} customValidity={fieldIssues.firstName} />
            <Field name={form.fields.lastName} placeholder="Surname" defaultValue={user.lastName} customValidity={fieldIssues.lastName} />
          </div>
          <Field name={form.fields.email} placeholder="Email" defaultValue={user.email} customValidity={fieldIssues.email} disabled />
        </Fields>
        <Button color="white" type="submit" className="popup-personal-settings__submit" {...formStatus} disabled={!formUpdated}>Save changes</Button>
      </Form>
    </PopupLayout>
  )
}

export default PopupPersonalSettings
